import { NavigationItem, NavigationList } from "./navigation-types"

const getNavigationItem = (
  navigationItem: NavigationItem,
  whichNavigationAmI: string
) => {
  const hasAtLeastOneChildPage =
    typeof navigationItem?.elements?.child_page?.value[0] === "object"
  const externalLink = navigationItem?.elements?.external_link?.value || ""
  let slug =
    navigationItem?.elements?.page?.value[0]?.elements?.slug?.value || ""
  const linkText = navigationItem?.elements?.link_text?.value || ""
  if (!externalLink && !slug && !hasAtLeastOneChildPage) {
    console.error(
      `${linkText} page has a broken link (${whichNavigationAmI} Navigation)`
    )
    slug = "/404"
  }
  return {
    id: navigationItem.id,
    linkText,
    externalLink,
    slug,
    svg_icon: navigationItem?.elements?.svg_icon?.value! ?? "",
  }
}

export const getFormattedNavigation = (
  navigationItems: NavigationItem[],
  whichNavigationAmI: string
): NavigationList => {
  const navigationList = navigationItems.map(navItem => {
    const childPages =
      navItem?.elements?.child_page?.value?.map(childPage =>
        getNavigationItem(childPage, whichNavigationAmI)
      ) ?? []

    const navigationItem = getNavigationItem(navItem, whichNavigationAmI)

    return {
      ...navigationItem,
      childPages,
    }
  })

  return navigationList
}
