import React from "react"
import { AppProvider } from "./src/contexts"
import "./src/assets/scss/main.scss"
import "@fontsource/open-sans/300-italic.css"
import "@fontsource/open-sans/300.css"
import "@fontsource/open-sans/400-italic.css"
import "@fontsource/open-sans/400.css"
import "@fontsource/open-sans/700-italic.css"
import "@fontsource/open-sans/700.css"
import "@fontsource/poppins/400-italic.css"
import "@fontsource/poppins/400.css"
import "@fontsource/poppins/700-italic.css"
import "@fontsource/poppins/700.css"

export const wrapRootElement = ({ element }) => {
  return <AppProvider>{element}</AppProvider>
}
