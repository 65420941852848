import React from "react"
import { createContext } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { IntegrationsSettingsContextData, IntegrationsSettingsProviderProps, GraphQLContextData, StaticReviewOutput } from "./integrationsSettings-types"

const IntegrationsSettingsContext = createContext<IntegrationsSettingsContextData>({} as IntegrationsSettingsContextData)

const IntegrationsSettingsProvider = ({ children }: IntegrationsSettingsProviderProps) => {
  const data = useStaticQuery<GraphQLContextData>(getIntegrationsSettinsData)

  const { 
    fieldroutes_portal,
    fieldroutes_reviews,
    fieldroutes_review_images,
    google_reviews_url,
    static_review_items,
    fieldroutes_review_background_image,
    custom_link_text_fieldroutes_reviews,
    custom_link_text_fieldroutes_portal,
    buy_online_get_started_button
  } = data.kontentItemSettings?.elements

  const integrationButton = buy_online_get_started_button?.value

  const hasFieldroutesReviews = fieldroutes_reviews?.value[0]?.codename! === "yes" ? true : false
  const customLinkTextFieldroutesReviews = custom_link_text_fieldroutes_reviews?.value ?? ""

  const fieldroutesReviewImages = fieldroutes_review_images?.modular_content?.map((reviewImage) => {
    return {
      id: reviewImage.id,
      single_image: reviewImage?.elements?.single_image?.value[0],
    }
  }) ?? []

  const hasFieldroutesPortal = fieldroutes_portal?.value[0]?.codename! === "yes" ? true : false
  const customLinkTextFieldroutesPortal = custom_link_text_fieldroutes_portal?.value ?? ""
  const googleReviewsUrl = google_reviews_url?.value ?? ""

  const staticReviews: StaticReviewOutput[] = static_review_items?.modular_content?.map(reviewItem => {
    return {
      heading: reviewItem?.elements?.heading?.value ?? "",
      location: reviewItem?.elements?.location?.value ?? "",
      rating: reviewItem?.elements?.rating?.value[0]?.codename ?? "",
      reviewSourceLink: reviewItem?.elements?.review_source_link?.value ?? "",
      reviewer: reviewItem?.elements?.reviewer?.value ?? "",
      review: reviewItem?.elements?.review?.value ?? "",
      reviewBackgroundImage: reviewItem?.elements?.review_background_image?.value[0] ?? undefined,
      reviewImage: reviewItem?.elements?.review_image?.value[0] ?? undefined
    }
  }) ?? []

  const fieldroutesReviewBackgroundImage = fieldroutes_review_background_image?.value[0]
  
  return (
    <IntegrationsSettingsContext.Provider
      value={{
        hasFieldroutesReviews,
        fieldroutesReviewImages,
        hasFieldroutesPortal,
        googleReviewsUrl,
        staticReviews,
        fieldroutesReviewBackgroundImage,
        customLinkTextFieldroutesReviews,
        customLinkTextFieldroutesPortal,
        integrationButton
      }}
    >
      {children}
    </IntegrationsSettingsContext.Provider>
  )
}

export const getIntegrationsSettinsData = graphql`
  query IntegrationsSettingsContext {
    kontentItemSettings {
      elements {
        buy_online_get_started_button {
          value {
            ...AnchorLinkFragment
            ...ButtonFragment
          }
        }
        static_review_items {
          modular_content {
            ...ReviewFragment
          }
        }
        fieldroutes_portal {
          value {
            codename
          }
        }
        custom_link_text_fieldroutes_portal {
          value
        }
        fieldroutes_reviews {
          value {
            codename
          }
        }
        custom_link_text_fieldroutes_reviews {
          value
        }
        fieldroutes_review_images {
          modular_content {
            id
            ...SingleImageFragment
          }
        }
        fieldroutes_review_background_image {
          value {
            ...KenticoAssetElementFragment
          }
        }
        ...GoogleReviewsFragment
      }
    }
  }
`

export const reviewFragment = graphql`
fragment ReviewFragment on kontent_item_review {
  id
  elements {
    heading {
      value
    }
    location {
      value
    }
    rating {
      value {
        codename
      }
    }
    review {
      value
    }
    review_background_image {
      value {
        ...KenticoAssetElementFragment
      }
    }
    review_image {
      value {
        ...KenticoAssetElementFragment
      }
    }
    review_source_link {
      value
    }
    reviewer {
      value
    }
  }
}
`

export { IntegrationsSettingsProvider, IntegrationsSettingsContext }
