module.exports = [{
      plugin: require('../../gatsby-theme-allagash/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Allagash Pest Sales","short_name":"Allagash Pest Sales","start_url":"/","theme_color":"#fff","background_color":"#fff","display":"standalone","icon":"src/images/placeholder-icon.png","include_favicon":false,"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","cacheDigest":"827e067a49cf9e155aadcc17b5c38041"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://allagash-pest.fieldroutesthemes.com","noQueryString":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-breadcrumb/gatsby-browser.js'),
      options: {"plugins":[],"useAutoGen":true,"autoGenHomeLabel":"Home","exclude":["/dev-404-page/","/404/","/404.html","/offline-plugin-app-shell-fallback/"],"crumbLabelUpdates":[{"pathname":"/blog","crumbLabel":"Blog"},{"pathname":"/where-we-service","crumbLabel":"Where We Service"}]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager-timeout/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PHR5M4C","includeInDevelopment":false,"timeout":5000,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"page-change"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-200},
    },{
      plugin: require('../../gatsby-theme-allagash/gatsby-browser.js'),
      options: {"plugins":[],"projectId":"84740e00-254d-008b-e84e-3d94eb7638bc","authorizationKey":"ew0KICAiYWxnIjogIkhTMjU2IiwNCiAgInR5cCI6ICJKV1QiDQp9.ew0KICAianRpIjogImI5YTE5YWI4NWU0YjRjMWQ4NjU4NTViMGM4YTA2YjBmIiwNCiAgImlhdCI6ICIxNjU2NjA3OTIwIiwNCiAgImV4cCI6ICIyMDAyMjA3OTIwIiwNCiAgInZlciI6ICIxLjAuMCIsDQogICJwcm9qZWN0X2lkIjogIjg0NzQwZTAwMjU0ZDAwOGJlODRlM2Q5NGViNzYzOGJjIiwNCiAgImF1ZCI6ICJwcmV2aWV3LmRlbGl2ZXIua2VudGljb2Nsb3VkLmNvbSINCn0.2zgjTjlIiSYQ7xElkNM5iFLHwEHk8wNUwohJsPPfabk","usePreviewUrl":"false","siteUrl":"https://allagash-pest.fieldroutesthemes.com","title":"Allagash Pest Sales","description":"Demo - Allagash Pest - Sales","domainVerification":"meta-tag","gtmId":"GTM-PHR5M4C","whereWeServicePageSlug":"where-we-service","whereWeServiceBreadcrumbLabel":"Where We Service"},
    }]
